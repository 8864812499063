import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'reactstrap';
import { MutedIcon, UnmutedIcon, PlayIcon } from '../Icons';

const VideoPlayer = (props) => {
  const { src, poster, captureMode, audioEnabled } = props;
  const isMuteToggleVisible = captureMode === 'video' && audioEnabled;

  // Video element
  const video = useRef();
  // Video loading state
  const [isLoading, setIsLoading] = useState(true);
  // Muted state
  const [isMuted, setIsMuted] = useState(true);
  // Play button visibility
  const [isPlayButtonVisible, setIsPlayButtonVisible] = useState(false);

  // Attempt to play the video
  const play = async () => {
    try {
      setIsPlayButtonVisible(false);
      await video.current.play();
    } catch (err) {
      // Show play button if playback has failed
      // (Most likely the browser has rejected automatic playback)
      setIsPlayButtonVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleMute = (e) => {
    e.preventDefault();
    setIsMuted(!isMuted);
  };

  // Load the video src and autoplay if possible
  useEffect(() => {
    if (!video.current) {
      return;
    }
    setIsLoading(true);

    // Mute video, see https://github.com/facebook/react/issues/10389
    video.current.muted = true;
    // Start loading video
    video.current.load();
    // Start playing video when loaded enough
    video.current.addEventListener('canplay', play);
  }, [src]);

  return (
    <div className="video-wrap">
      <video
        muted={isMuted || null}
        loop
        playsInline
        preload="none"
        src={src}
        poster={poster}
        type="video/mp4"
        className="image-container video-container"
        ref={video}
        key={src}
      ></video>
      {isLoading && (
        <div className="video-wrap__spinner">
          <Spinner color="light" />
        </div>
      )}
      {isMuteToggleVisible && (
        <button className="video-wrap__unmute" onClick={toggleMute}>
          <span className="sr-only">Toggle audio</span>
          {isMuted ? <MutedIcon color="#ccc" /> : <UnmutedIcon color="#ccc" />}
        </button>
      )}
      {isPlayButtonVisible && (
        <button
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(0,0,0,0.25)',
            border: 0,
            outline: 0,
          }}
          onClick={play}
        >
          <div style={{ width: '100px', height: '100px' }}>
            <PlayIcon color="#ffffff" />
          </div>
        </button>
      )}
    </div>
  );
};

export default VideoPlayer;
