import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from './event/VideoPlayer';
import Utils from '../utils/utils';

class Media extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      server: false,
      portrait: false,
    };
  }

  componentDidMount() {
    this.setState({ server: true });
    const myImg = document.getElementById(this.props.id);
    if (myImg) {
      if (Utils.isPortrait(myImg)) {
        this.setState({ portrait: true });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.src !== this.props.src) {
      return true;
    }

    if (nextState.server && !this.state.server) {
      return true;
    }

    return false;
  }

  render() {
    const { portrait } = this.state;
    const classContainer = portrait ? 'image-portrait-container' : 'image-landscape-container';

    if (this.props.mediaType === 'image' || !this.state.server) {
      return (
        <div className={classContainer}>
          <img key={this.props.src} id={this.props.id} className="image" src={this.props.original} alt="" />
        </div>
      );
    }
    return (
      <VideoPlayer
        poster={this.props.poster}
        src={this.props.original}
        alt=""
        captureMode={this.props.captureMode}
        audioEnabled={this.props.audioEnabled}
      />
    );
  }
}

Media.propTypes = {
  mediaType: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  original: PropTypes.string.isRequired,
};

export default Media;
