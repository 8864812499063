import { connect } from 'react-redux';
import _ from 'lodash';
import { getContrastColor } from '../utils/utils';
import Photo from '../components/Photo';

const getBackground = (state, eventId) => {
  if (typeof state.events[eventId] !== 'undefined' && state.events[eventId] !== null) {
    return state.events[eventId].background;
  }
  return '';
};

const getBackgroundColor = (state, eventId) => {
  if (typeof state.events[eventId] !== 'undefined' && state.events[eventId] !== null) {
    return state.events[eventId].backgroundColor;
  }
  return '';
};

const getFontColor = (state, eventId) => {
  if (typeof state.events[eventId] !== 'undefined' && state.events[eventId] !== null) {
    return state.events[eventId].fontColor;
  }
  return '';
};

const getImage = (state, imageId) => {
  if (typeof state.photos[imageId] !== 'undefined' && state.photos[imageId] !== null) {
    return state.photos[imageId];
  }
  return { src: '', original: '', type: '', id: '', isTemplate: false, captureMode: '' };
};

const getTemplate = (state, imageId) => {
  if (typeof state.templates[imageId] !== 'undefined' && state.templates[imageId] !== null) {
    return state.templates[imageId];
  }
  return [];
};

const isFetching = (state) => {
  if (state.isFetching.count) {
    return true;
  }
  return false;
};

const mapStateToProps = (state, ownProps) => {
  let event = state.events[ownProps.eventId];
  if (!event) {
    event = state.activeEvent;
  }

  const eventInState = typeof event !== 'undefined' && event !== null;
  const photoId = ownProps.photoId ? ownProps.photoId : Object.keys(state.photos)[0];

  return {
    image: getImage(state, photoId),
    background: getBackground(state, ownProps.eventId),
    backgroundColor: getBackgroundColor(state, ownProps.eventId),
    eventId: ownProps.eventId,
    mediaId: photoId,
    loading: isFetching(state),
    fontColor: state.activeEvent.fontColor,
    pageType: 'photo',
    templateImages: getTemplate(state, ownProps.photoId),
    mediaNotFound: state.mediaNotFound,
    eventNotFound: state.eventNotFound,
    downloadLink: state.downloadLink,
    shareImage: state.shareImage,
    title: state.activeEvent.title,
    sharePhoto: state.sharePhoto,
    accentColor: eventInState ? event.accentColor : '',
    contrastColor: eventInState ? getContrastColor(event.accentColor) : '',
    subtitle: eventInState ? event.subtitle : '',
    buttonFlag: eventInState ? event.buttonFlag : false,
    buttonText: eventInState ? event.buttonText : '',
    buttonLink: eventInState ? event.buttonLink : '',
    logo: eventInState ? event.logo : '',
    logoLink: eventInState ? event.logoLink : null,
    analytics: eventInState ? event.analytics : null,
    companySection: eventInState && event.liveGallery ? event.liveGallery.companySection : false,
    socialMediaSection: eventInState && event.liveGallery ? event.liveGallery.socialMediaSection : false,
    socialMediaCaptions: eventInState && event.liveGallery ? event.liveGallery.socialMediaCaptions : null,
    fullGalleryEnabled: eventInState ? event.fullGalleryEnabled : false,
    audioEnabled: eventInState ? event.audioEnabled : false,
    booth: ownProps.booth,
  };
};

const PhotoConnect = connect(mapStateToProps)(Photo);

export default PhotoConnect;
