import React, { useEffect } from 'react';

const MediaOverlayButton = (props) => {
  const { id, direction, handleClick, buildingItems, color } = props;

  const handleKeyPress = (event) => {
    const keyToTest = direction === 'prev' ? 'ArrowLeft' : 'ArrowRight';
    const { code } = event;
    if (!code) {
      return;
    }
    if (code === keyToTest) {
      handleClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);

    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, []);

  let className = `event-overlay__btn event-overlay__btn--${direction}`;
  if (!id || buildingItems) {
    className += ' event-overlay__btn--disabled';
  }

  return (
    <button className={className} onClick={handleClick}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 84">
        <path
          d="M36,42c0,0.4-0.1,0.9-0.4,1.2l-32,40c-0.7,0.9-1.9,1-2.8,0.3c-0.9-0.7-1-1.9-0.3-2.8l31-38.8L0.4,3.2   c-0.7-0.9-0.5-2.1,0.3-2.8c0.9-0.7,2.1-0.6,2.8,0.3l32,40C35.9,41.1,36,41.6,36,42z"
          fill={color}
        />
      </svg>
      {direction === 'prev' ? 'Previous' : 'Next'}
    </button>
  );
};

export default MediaOverlayButton;
