import React, { Component, useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import Datetime from 'react-datetime';
import fscreen from 'fscreen';
import moment from 'moment';
import useInterval from '@use-it/interval';
import _ from 'lodash';
import useTimeout from 'use-timeout';
import preload from '../../utils/preload';
import { eventSlideshowSetIndex, requestMedia, requestMedias } from '../../actions/actions';
import { CloseIcon, FullscreenIcon } from '../Icons';

const EventSlideshow = (props) => {
  const { onClose = () => {}, slideDuration = 5000, fetchInterval = 5000 } = props;

  const dispatch = useDispatch();
  const {
    isLoading, // Media loading state
    slideIndex, // Index of the current slide in the queue
    data: queue, // Queue of slides to play
  } = useSelector((store) => store.eventSlideshowQueue);

  // Object containing all fetched captures, keyed by mediaId
  const photos = useSelector((store) => store.photos);
  // The current event ID
  const eventId = useSelector((store) => store.activeEvent?.eventId);
  // Capture Kinds to show
  const captureKinds = useSelector((store) => store.activeEvent?.liveGallery.displayCaptureKinds);

  // Slideshow playing state
  const [isPlaying, setIsPlaying] = useState(false);

  const localRequestMedia = () => {
    const filter = {
      filterByCaptureMode: false,
      filterByTime: false,
      isMobile: false,
      order: 'DESC',
      captureKind: captureKinds,
    };
    dispatch(requestMedias(eventId, null, null, filter));
  };

  // Go to the next slide
  const goToNext = async () => {
    let newIndex = slideIndex + 1;
    // Reset to 0 when we reach the end
    if (newIndex > queue.length) {
      newIndex = 0;
    }
    // Set new index in store
    dispatch(eventSlideshowSetIndex(newIndex));
    // If we are nearing the end of the queue, load another page of captures
    if (newIndex >= queue.length - 1) {
      localRequestMedia();
    }
  };
  // While playing, call goToNext at an interval (slideDuration)
  useInterval(goToNext, isPlaying ? slideDuration : null);

  // Keep track of the last time we polled for new captures
  const [lastFetchedTime, setLastFetchedTime] = useState(moment());
  // Fetch newly-uploaded captures since the lastFetchedTime
  const fetchNewCaptures = () => {
    const now = moment();
    dispatch(
      requestMedias(eventId, null, null, {
        dateFrom: lastFetchedTime,
        dateTo: now,
        order: 'ASC',
        captureKind: captureKinds,
      })
    );
    setLastFetchedTime(now);
  };
  // While playing, check for newly-uploaded captures at an interval (fetchInterval)
  useInterval(fetchNewCaptures, isPlaying ? fetchInterval : null);

  // Whether the slideshow control buttons are currently visible
  const [controlsAreVisible, setControlsAreVisible] = useState(true);
  // Fade controls after a timeout
  const [controlFadeTimer, setControlFadeTimer] = useState(null);
  useTimeout(() => setControlsAreVisible(false), controlFadeTimer > 0 ? controlFadeTimer : null);

  // Toggle fullscreen mode
  const toggleFullscreen = () => {
    if (fscreen.fullscreenEnabled) {
      if (fscreen.fullscreenElement !== null) {
        fscreen.exitFullscreen();
      } else {
        fscreen.requestFullscreen(document.documentElement);
      }
    }
  };

  // Play the slideshow
  const play = () => {
    if (!queue.length) {
      localRequestMedia();
    }
    setIsPlaying(true);
  };

  // Start playing on mount
  useEffect(() => {
    play();

    // Show the controls when the mouse is moved
    const handleMouseMove = () => {
      setControlsAreVisible(true);
      setControlFadeTimer(2000);
    };
    window.addEventListener('mousemove', handleMouseMove, false);

    // Close the slideshow when the Escape key is pressed
    const handleKeyDown = (event) => {
      const { code } = event;
      if (!code) {
        return;
      }
      switch (code) {
        case 'Escape':
          onClose();
          break;
        default:
      }
    };
    window.addEventListener('keydown', handleKeyDown, false);

    return () => {
      // Remove event listeners on unmount
      window.removeEventListener('mousemove', handleMouseMove, false);
      window.removeEventListener('keydown', handleKeyDown, false);
    };
  }, []);

  // Preload captures before they are displayed
  useEffect(() => {
    const nextIndex = slideIndex + 1;
    const nextCapture = photos[queue[nextIndex]?.mediaId];
    if (!nextCapture || !nextCapture.original) {
      return;
    }
    preload(nextCapture.original);
  }, [queue, slideIndex]);

  // Capture to render for the current slide
  const currentCapture = photos[queue[slideIndex]?.mediaId];

  const renderCapture = (media) => {
    if (!media || !media.type || !media.original) {
      return null;
    }
    switch (media.type) {
      case 'image':
        return <img src={media.original} alt="" />;
      case 'video':
        return (
          <video
            autoPlay
            muted={false}
            loop
            playsInline
            disableRemotePlayback={true}
            src={media.original}
            type="video/mp4"
          ></video>
        );
      default:
        return null;
    }
  };

  return (
    <div className="slideshow">
      <div className={['slideshow__btns', !controlsAreVisible && 'slideshow__btns--hidden'].join(' ')}>
        <button className="slideshow__btn slideshow__btn--fullscreen" onClick={toggleFullscreen}>
          <FullscreenIcon color="#ffffff" />
          <span className="sr-only">Toggle Fullscreen</span>
        </button>

        <button className="slideshow__btn slideshow__btn--close" onClick={onClose}>
          <CloseIcon color="#ffffff" />
          <span className="sr-only">Close</span>
        </button>
      </div>

      <div className="slideshow__inner">
        {currentCapture ? (
          <ReactCSSTransitionReplace
            transitionName="cross-fade"
            transitionEnterTimeout={1000}
            transitionLeaveTimeout={1000}
          >
            <div key={`slide_${currentCapture.id}`} className="slideshow__slide">
              {renderCapture(currentCapture)}
            </div>
          </ReactCSSTransitionReplace>
        ) : (
          <div className="slideshow__placeholder">
            <h2>No Captures Yet</h2>
            <p>Media will load in automatically as it is captured.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventSlideshow;
