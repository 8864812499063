import React from 'react';
import { FacebookButton, TwitterButton } from 'react-social';
import { FacebookIcon, TwitterIcon } from '../Icons';

export const ShareList = (props) => {
  const shareString = 'Share to';

  return (
    <div className="share-list">
      <h3 className="share-list__title">Share</h3>
      <ul className="share-list__list">
        <li className="share-list__item">
          <FacebookButton url={props.url} appId="588603561481264">
            <FacebookIcon />
            {`${shareString} Facebook`}
          </FacebookButton>
        </li>
        <li className="share-list__item">
          <TwitterButton url={props.url}>
            <TwitterIcon />
            {`${shareString} Twitter`}
          </TwitterButton>
        </li>
      </ul>
    </div>
  );
};
