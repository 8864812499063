import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import Swipe from 'react-easy-swipe';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { requestMedia } from '../../actions/actions';
import Photo from '../../containers/photoConnect';
import MediaOverlayButton from './MediaOverlayButton';
import { CloseIcon } from '../Icons';

class MediaOverlay extends React.Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.goToPrevious = this.goToPrevious.bind(this);
    this.goToNext = this.goToNext.bind(this);

    this.state = {
      swipeDirection: null,
    };
  }

  onClose(e) {
    this.props.onClose();
  }

  goToPrevious() {
    this.setState({ swipeDirection: 'left' });
    this.props.setActiveMedia(this.props.prevId);
  }

  goToNext() {
    this.setState({ swipeDirection: 'right' });
    this.props.setActiveMedia(this.props.nextId);
  }

  render() {
    const { photoIndex } = this.props;

    return (
      <Modal
        isOpen={this.props.overlayOpen}
        toggle={this.onClose}
        centered={true}
        fade={false}
        className="event-overlay fullscreen-overlay"
        backdropClassName="event-overlay-backdrop"
      >
        <div className="event-overlay__toolbar" style={{ backgroundColor: this.props.accentColor }}>
          <button className="event-overlay__close" onClick={this.onClose} style={{ color: this.props.contrastColor }}>
            Close
            <CloseIcon color={this.props.contrastColor} />
          </button>
        </div>

        <MediaOverlayButton
          direction="prev"
          id={this.props.prevId}
          handleClick={this.goToPrevious}
          color={this.props.contrastColor}
          buildingItems={this.props.buildingItems}
        />

        <ModalBody>
          {this.props.activeMedia && (
            <Swipe onSwipeLeft={this.goToNext} onSwipeRight={this.goToPrevious} tolerance={100}>
              <Photo
                eventId={this.props.eventId}
                photoId={this.props.activeMedia.id}
                beta={this.props.beta}
                isServer={this.props.isServer}
                popup={true}
                audioEnabled={this.props.audioEnabled}
                captureMode={this.props.captureMode}
              />
            </Swipe>
          )}
        </ModalBody>

        <MediaOverlayButton
          direction="next"
          id={this.props.nextId}
          handleClick={this.goToNext}
          color={this.props.contrastColor}
          buildingItems={this.props.buildingItems}
        />
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const event = state.activeEvent;
  const { eventId } = event;

  return {
    eventId: event.eventId,
    activeMedia: state.activeMedia,
    photos: state.photos,
    photoIndex: state.events[eventId]?.eventMedias?.map((m) => m.mediaId) ?? [],
    accentColor: event.accentColor,
    contrastColor: event.contrastColor,
    beta: state.beta,
    isServer: state.isServer,
  };
};

export default connect(mapStateToProps)(MediaOverlay);
