import React from 'react';
import Ink from 'react-ink';
import onClickOutside from 'react-onclickoutside';
import { CloseIcon } from '../Icons';

class EventToolbarInfoButton extends React.Component {
  constructor(props) {
    super(props);

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);

    this.state = {
      open: false,
    };
  }

  showMenu(e) {
    e.preventDefault();

    this.setState({ open: true });
  }

  closeMenu() {
    this.setState({ open: false });
  }

  handleClickOutside = (e) => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="event-toolbar__drop drop">
        <button
          className="drop__toggle"
          onClick={this.showMenu}
          title={this.props.label}
          aria-haspopup="true"
          aria-expanded={this.state.open}
        >
          {this.props.icon}
          {this.props.label}
        </button>

        {this.state.open && (
          <div
            className="drop__menu"
            ref={(element) => {
              this.menu = element;
            }}
          >
            {this.props.content}

            <button
              className="modal-close drop__close"
              onClick={this.closeMenu}
              ref={(element) => {
                this.closeButton = element;
              }}
            >
              <CloseIcon />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default onClickOutside(EventToolbarInfoButton);
