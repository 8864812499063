import { connect } from 'react-redux';
import { getContrastColor } from '../utils/utils';
import Header from '../components/event/Header';

const matchEvent = (state, eventId) => {
  if (typeof state.events[eventId] !== 'undefined' && state.events[eventId] !== null) {
    return state.events[eventId];
  }
  return '';
};

const mapStateToProps = (state, ownProps) => {
  const event = state.activeEvent;
  return {
    contactEnabled: event.contactEnabled,
    contactOwner: event.contactOwner,
    fullGalleryEnabled: event.fullGalleryEnabled,
    title: event ? event.title : '',
    date: event ? event.date : '',
    logo: event?.logo && event?.logo !== '' ? event?.logo : 'none',
    background: event?.background && event?.background !== '' ? event?.background : 'none',
    accentColor: event ? event.accentColor : '',
    contrastColor: event ? getContrastColor(event.accentColor) : '',
    slim: ownProps.slim,
    eventId: ownProps.eventId,
    subtitle: event ? event.subtitle : '',
    buttonFlag: event ? event.buttonFlag : '',
    buttonLink: event ? event.buttonLink : '',
    buttonText: event ? event.buttonText : '',
    logoLink: event ? event.logoLink : null,
    photos: state ? state.photos : {},
    analytics: event ? event.analytics : {},
    environment: state.environment,
  };
};

const HeaderConnect = connect(mapStateToProps)(Header);

export default HeaderConnect;
