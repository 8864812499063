import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import Media from './Media';
import Arrows from './core/Arrows';

class Template extends React.Component {
  render() {
    const medias = this.props.images.map((image, index) => (
      <div key={image.id}>
        <Media id={image.id} mediaType={image.type} src={image.src} original={image.original} />
      </div>
    ));
    const imageCount = this.props.images.length;
    const dots = [];
    const dot = String.fromCharCode(0x2022);
    for (let i = 0; i < imageCount; i += 1) {
      dots.push(<li style={{ color: this.props.fontColor }}>{dot}</li>);
    }
    const settings = {
      dots: true,
      dotsClass: 'slick-dots',
      customPaging: (i) => <button style={{ color: this.props.fontColor }}>{i + 1}</button>,
      prevArrow: <SamplePrevArrow fontColor={this.props.fontColor} backgroundColor={this.props.backgroundColor} />,
      nextArrow: <SampleNextArrow fontColor={this.props.fontColor} backgroundColor={this.props.backgroundColor} />,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      afterChange: (i) => this.props.onChange(this.props.images[i]),
    };
    return <Slider {...settings}>{medias}</Slider>;
  }
}

const SampleNextArrow = (props) => {
  const divStyle = {
    backgroundColor: `${props.backgroundColor}75`,
  };

  return (
    <div className={`next-arrow-template ${props.className}`} style={divStyle} onClick={props.onClick}>
      <Arrows fontColor={props.fontColor} direction="next" />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const divStyle = {
    backgroundColor: `${props.backgroundColor}75`,
  };

  return (
    <div className={`prev-arrow-template ${props.className}`} style={divStyle} onClick={props.onClick}>
      <Arrows fontColor={props.fontColor} direction="prev" />
    </div>
  );
};

Template.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
  fontColor: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Template;
