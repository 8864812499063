import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import autoBind from 'auto-bind';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import {
  requestEvent,
  requestMedia,
  updateDownloadLink,
  updateShareImage,
  sharePhotoByEmail,
} from '../actions/actions';
import Media from './Media';
import Logo from './core/Logo';
import CtaButton from './event/CtaButton';
import Button from './core/Button';
import Template from './Template';
import { Router } from '../routes';
import { logEvent } from '../utils/analytics';
import CompanyInfo from './core/CompanyInfo';

const SocialIcons = dynamic(import('./core/SocialIcons'), { ssr: false, loading: () => <div></div> });
const { liveGalleryURL } = require('../constants').get();

class PhotoPage extends React.Component {
  constructor(props) {
    super(props);
    const betaFeatures = this.props.beta;

    this.state = {
      beta: betaFeatures && betaFeatures === 'true',
      currentImageId: this.props.mediaId,
    };

    autoBind(this);
  }

  componentDidMount() {
    const { eventId, photoId, isServer, mediaNotFound, eventNotFound, popup, dispatch, image } = this.props;

    if (!isServer && !popup) {
      // Request event data
      dispatch(requestEvent(eventId, isServer));

      // Request media data if applicable
      if (typeof photoId !== 'undefined') {
        dispatch(requestMedia(photoId, eventId));
      }
    }

    if (eventNotFound) {
      Router.pushRoute('/');
    } else if (photoId && mediaNotFound) {
      Router.pushRoute(`/${eventId}`);
    }

    // Set image data
    this.updateCurrentImage(image);
  }

  // Update current image if media ID changes
  componentDidUpdate(prevProps) {
    const { photoId, image } = this.props;
    if (photoId !== prevProps.photoId) {
      this.updateCurrentImage(image);
    }
  }

  updateCurrentImage(image) {
    const { eventId, photoId } = this.props;
    if (!photoId) {
      return false;
    }
    this.setState({
      currentImageId: image.id,
      route: `${liveGalleryURL}/${eventId}/${photoId}`,
    });
    this.props.dispatch(updateDownloadLink(image.original));
  }

  handleShare(email) {
    const { eventId, image } = this.props;
    const mediaId = image.id;

    if (!mediaId) {
      return false;
    }

    if (this.props.image.isTemplate) {
      this.props.dispatch(sharePhotoByEmail(email, eventId, this.state.currentImageId));
    } else {
      this.props.dispatch(sharePhotoByEmail(email, eventId, mediaId));
    }
  }

  handleShareButtonClick(body) {
    const { image, eventId } = this.props;
    const mediaId = image.id;

    if (mediaId) {
      logEvent('media', `${body.buttonType} share`, `${eventId}/${mediaId}`);
    }
  }

  render() {
    const { sharePhoto, loading, image, buttonFlag, buttonText, buttonLink, booth } = this.props;

    let media = null;
    let shareImage = null;

    if (!loading && image.src) {
      if (image.isTemplate) {
        if (this.props.shareImage !== 'unavailable') {
          shareImage = this.props.shareImage;
        } else {
          shareImage = image.src;
        }

        media = (
          <Template
            images={this.props.templateImages}
            fontColor={this.props.fontColor}
            backgroundColor={this.props.backgroundColor}
            onChange={this.updateCurrentImage}
          />
        );
      } else {
        shareImage = image.src;
        media = (
          <Media
            id={image.id}
            mediaType={image.type}
            src={image.original}
            poster={image.static}
            original={this.props.downloadLink}
            isServer={this.props.isServer}
            captureMode={image.captureMode ? image.captureMode.toLowerCase() : null}
            audioEnabled={this.props.audioEnabled}
          />
        );
      }
    }

    let classPhoto = 'photocontainer';
    let classPhotoPage = 'photopage';
    const url = `https://boothpics.com${this.props.router.asPath}`;
    if (this.props.beta) {
      classPhoto = 'photocontainer-beta';
      classPhotoPage = 'photopage-beta';
    }

    const mediaViewerClasses = ['media-viewer'];
    let mediaContent;
    if (image.src) {
      mediaContent = <div key={`${image.id}-full`}>{media}</div>;
    } else {
      mediaViewerClasses.push('media-viewer--preview');
      mediaContent = (
        <div className="no-captures">
          <div>
            <img className="no-captures__img" src="/static/frame.svg" />
            <h2 className="no-captures__title">No Captures</h2>
            <p className="no-captures__text">Preview will appear once the gallery is populated.</p>
          </div>
        </div>
      );
    }

    const btnClasses = ['btn'];

    return (
      !this.props.mediaNotFound && (
        <div>
          <Head>
            <meta property="og:title" content={this.props.title} />
            <meta property="og:description" content="Check out my photobooth photo!" />
            <meta property="og:image" content={shareImage} />
            <meta property="og:url" content={url} />
            <meta name="twitter:card" content="photo" />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:title" content={this.props.title} />
            <meta name="twitter:description" content="Check out my photobooth photo!" />
            <meta name="twitter:image" content={shareImage} />
            <meta name="theme-color" content={this.props.accentColor} />
          </Head>
          <div className={mediaViewerClasses.join(' ')}>
            <div className="media-viewer__media" style={{ backgroundColor: this.props.accentColor }}>
              {mediaContent}
            </div>
            <div className="media-viewer__info">
              <div>
                {this.props.logo && (
                  <div className="media-viewer__logo">
                    <Logo logoSrc={this.props.logo} link={this.props.logoLink} />
                  </div>
                )}

                <h2 className="media-viewer__title">{this.props.title}</h2>
                {this.props.subtitle && <p className="media-viewer__subtitle">{this.props.subtitle}</p>}

                <div className="media-viewer__btns">
                  {!!booth && (
                    <Button href={`${liveGalleryURL}/booth/${booth}`} type="dark">
                      Take another
                    </Button>
                  )}

                  {buttonFlag && buttonText && buttonLink && <CtaButton />}
                </div>

                {this.props.analytics && this.props.fullGalleryEnabled && (
                  <a className="media-viewer__viewall" href={`/${this.props.eventId}`}>
                    View all {this.props.analytics.totalPhotos} captures
                  </a>
                )}

                {!this.props.analytics && this.props.fullGalleryEnabled && (
                  <a className="media-viewer__viewall" href={`/${this.props.eventId}`}>
                    View all captures
                  </a>
                )}

                <div>
                  <h3>Share</h3>
                  <SocialIcons
                    sharePhoto={sharePhoto}
                    onShareButtonClick={this.handleShareButtonClick}
                    url={this.state.route}
                    onShare={this.handleShare.bind(this)}
                    shareEmailBody={this.props.shareEmailBody}
                    fontColor={this.props.fontColor}
                    eventId={this.props.eventId}
                    mediaId={image.id}
                    mediaType={image.type}
                    originalLink={this.props.downloadLink}
                    beta={this.props.beta}
                    accentColor={this.props.accentColor}
                    contrastColor={this.props.contrastColor}
                    socialMediaCaptions={this.props.socialMediaCaptions}
                    linkOnly={!this.props.socialMediaSection}
                  />
                </div>

                {this.props.companySection && <CompanyInfo showDisclaimers={!this.props.popup} />}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

PhotoPage.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    original: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    isTemplate: PropTypes.bool.isRequired,
  }).isRequired,
  background: PropTypes.string,
  backgroundColor: PropTypes.string,
  fontColor: PropTypes.string,
  eventId: PropTypes.string.isRequired,
  mediaId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  pageType: PropTypes.string.isRequired,
  templateImages: PropTypes.arrayOf(PropTypes.object),
  downloadLink: PropTypes.string.isRequired,
  shareImage: PropTypes.string,
};

export default withRouter(PhotoPage);
