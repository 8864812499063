export default function load(image, attributes) {
  let loadedImage = image;
  if (!loadedImage) {
    return Promise.reject();
  }
  if (typeof loadedImage === 'string') {
    /* Create a <img> from a string */
    const src = loadedImage;
    const ext = src.substr(src.lastIndexOf('.') + 1);

    if (ext === 'mp4') {
      loadedImage = document.createElement('video');
      loadedImage.setAttribute('src', src);
      loadedImage.setAttribute('muted', 'muted');
      loadedImage.setAttribute('playsinline', 'playsinline');
      loadedImage.load();
    } else {
      loadedImage = new Image();
      loadedImage.src = src;
    }

    Object.keys(attributes || {}).forEach((name) => loadedImage.setAttribute(name, attributes[name]));
  } else if (loadedImage.length !== undefined) {
    /* Treat as multiple images */

    // Momentarily ignore errors
    const reflected = [].map.call(loadedImage, (img) => load(img, attributes).catch((err) => err));

    return Promise.all(reflected).then((results) => {
      const loaded = results.filter((x) => {
        return x.naturalWidth || x.getAttribute('data-can-play');
      });
      if (loaded.length === results.length) {
        return loaded;
      }
      return Promise.reject({
        loaded,
        errored: results.filter((x) => {
          return !x.naturalWidth && !x.getAttribute('data-can-play');
        }),
      });
    });
  } else if (loadedImage.tagName.toUpperCase() !== 'IMG' && loadedImage.tagName.toUpperCase() !== 'VIDEO') {
    return Promise.reject();
  }

  const promise = new Promise((resolve, reject) => {
    function fulfill() {
      if (loadedImage.naturalWidth || loadedImage.getAttribute('data-can-play')) {
        resolve(loadedImage);
      } else {
        reject(loadedImage);
      }
      loadedImage.removeEventListener('load canplaythrough', fulfill);
      loadedImage.removeEventListener('error', fulfill);
    }

    if (loadedImage.naturalWidth || loadedImage.getAttribute('data-can-play')) {
      // If the browser can determine the naturalWidth the
      // image is already loaded successfully
      resolve(loadedImage);
    } else if (loadedImage.complete) {
      // If the image is complete but the naturalWidth is 0px
      // it is probably broken
      reject(loadedImage);
    } else {
      if (loadedImage.tagName.toUpperCase() === 'VIDEO') {
        loadedImage.addEventListener('canplaythrough', () => {
          loadedImage.setAttribute('data-can-play', true);
          fulfill();
        });
      } else {
        loadedImage.addEventListener('load', fulfill);
      }
      loadedImage.addEventListener('error', fulfill);
    }
  });
  promise.image = loadedImage;
  return promise;
}
