import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Container } from 'reactstrap';
import Ink from 'react-ink';
import EventFilter from './EventFilter';
import EventToolbarInfoButton from './EventToolbarInfoButton';
import {
  KebabIcon,
  ShareIcon,
  FilterIcon,
  CloseIcon,
  FacebookIcon,
  TwitterIcon,
  SlideshowIcon,
  PlayIcon,
} from '../Icons';
import CompanyInfo from '../core/CompanyInfo';
import { ShareList } from '../core/ShareList';

class EventToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterString: 'Newest → Oldest',
      filtersOpen: false,
    };
    this.onFilterClick = this.onFilterClick.bind(this);
    this.filtersToggle = this.filtersToggle.bind(this);
    this.openSlideshow = this.openSlideshow.bind(this);
  }

  onFilterClick(filters) {
    this.props.onFilterClick(filters);

    // Build filter string
    const dateFormat = 'MMM D, h:mma';
    let filterString = '';
    if (filters.order === 'ASC') {
      filterString += 'Oldest → Newest';
    } else {
      filterString += 'Newest → Oldest';
    }

    this.setState({
      filterString,
      filtersOpen: false,
    });
  }

  filtersToggle() {
    this.setState((prevState) => ({
      filtersOpen: !prevState.filtersOpen,
    }));
  }

  openSlideshow() {
    this.props.openSlideshow();
  }

  render() {
    const { companySection, socialMediaSection, organization } = this.props;

    let disabled = '';
    if (this.props.loading) {
      disabled = 'disabled';
    }

    const shareList = <ShareList url={`https://boothpics.com/${this.props.eventId}`} />;

    const filtersActive = this.props.filters.dateTo || this.props.filters.dateFrom || this.props.filters.captureMode;

    const filterClasses = ['event-toolbar__filters'];
    if (this.props.accentColor === '#FFFFFF') {
      filterClasses.push('gray-border');
    }

    const plan = organization && organization.plan ? organization.plan.toLowerCase() : 'plus';
    const licenseCount = organization && organization.licenseCount ? organization.licenseCount : 0;

    return (
      <div
        className="event-toolbar"
        style={{
          backgroundColor: this.props.accentColor,
          color: this.props.contrastColor,
        }}
      >
        <Container fluid={true}>
          <div>
            <Row noGutters={true}>
              {companySection && (
                <Col>
                  <EventToolbarInfoButton
                    icon={<KebabIcon color={this.props.contrastColor} />}
                    label="More Info"
                    content={<CompanyInfo showDisclaimers={true} />}
                  />
                </Col>
              )}

              {socialMediaSection && (
                <Col>
                  <EventToolbarInfoButton
                    icon={<ShareIcon color={this.props.contrastColor} />}
                    label="Share"
                    content={shareList}
                  />
                </Col>
              )}

              {plan === 'pro' && (
                <Col>
                  <button
                    className="slideshow-btn d-sm-none"
                    onClick={this.openSlideshow}
                    style={{
                      color: this.props.contrastColor,
                    }}
                  >
                    <PlayIcon color={this.props.contrastColor} />
                    <span className="sr-only">Slideshow</span>
                  </button>
                </Col>
              )}
            </Row>
          </div>

          <div>
            <span className="event-toolbar__label">Sort</span>
            <button className={filterClasses.join(' ')} onClick={this.filtersToggle}>
              {this.state.filterString}
              <FilterIcon color={filtersActive ? '#000' : '#ccc'} />
              <Ink />
            </button>
          </div>

          <div className="d-none d-sm-block">
            {plan === 'pro' && (
              <button
                className="slideshow-btn"
                onClick={this.openSlideshow}
                style={{
                  color: this.props.contrastColor,
                }}
              >
                <PlayIcon color={this.props.contrastColor} />
                <span>Slideshow</span>
              </button>
            )}
          </div>
        </Container>

        <Modal
          isOpen={this.state.filtersOpen}
          toggle={this.filtersToggle}
          centered={true}
          className="fullscreen-overlay filter-overlay"
        >
          <ModalBody>
            <button className="modal-close" onClick={this.filtersToggle}>
              <CloseIcon />
            </button>
            <EventFilter
              onFilterClick={this.onFilterClick}
              filters={this.props.filters}
              startDate={this.props.startDate}
              accentColor={this.props.accentColor}
              contrastColor={this.props.contrastColor}
              apiName={this.props.apiName}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const event = state.activeEvent;

  return {
    eventId: event.eventId,
    accentColor: event.accentColor,
    contrastColor: event.contrastColor,
    beta: state.beta,
    isServer: state.isServer,
    startDate: event.dateMoment,
    filters: state.filters,
    companySection: event.liveGallery ? event.liveGallery.companySection : false,
    socialMediaSection: event.liveGallery ? event.liveGallery.socialMediaSection : false,
    organization: event.organization ? event.organization : {},
  };
};

export default connect(mapStateToProps)(EventToolbar);
