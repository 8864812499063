import React from 'react';
import ReactDOM from 'react-dom';
import autoBind from 'auto-bind';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import Head from 'next/head';
import debounce from 'lodash.debounce';
import { Element, scroller } from 'react-scroll';
import { Spinner } from 'reactstrap';
import fscreen from 'fscreen';
import { WAIT_FOR_ACTION } from 'redux-wait-for-action';
import { InfiniteScroll } from './webapp/organisms';
import { retrieveFromLocalStorage, saveToLocalStorage, removeFromLocalStorage } from '../utils/utils';
import {
  requestEvent,
  requestMedias,
  requestMedia,
  requestEventAccessWithPassword,
  requirePassword,
  setFilters,
  clearMedias,
  updateEventClearMedias,
  LOADING_MEDIAS,
} from '../actions/actions';
import GridTile from './event/GridTileCulled';
import EventPasswordModal from './EventPasswordModal';
import EventToolbar from './event/EventToolbar';
import MediaOverlay from './event/MediaOverlay';
import EventSlideshow from './event/EventSlideshow';
import { logEvent } from '../utils/analytics';

const { liveGalleryURL } = require('../constants').get();

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEventMounted: false,
      hasMounted: false,
      eventToken: null,
      photos: [],
      pagesLoaded: 0,
      loadMore: false,
      filtersModified: false,
    };
    autoBind(this);
    this.delayedResize = debounce(this.onResize.bind(this), 250);
  }

  componentDidMount() {
    const { eventNotFound, fullGalleryEnabled, eventId, id } = this.props;

    this.setState({
      hasMounted: true,
      windowSize: {
        h: window.innerHeight,
        w: window.innerWidth,
      },
      scrollY: 0,
    });

    if (!this.props.isServer) {
      this.props.dispatch(requirePassword(eventId));
    }
    if (eventNotFound || !fullGalleryEnabled) {
      window.location = liveGalleryURL;
    }

    // Repack grid items on debounced resize
    window.addEventListener('resize', this.delayedResize);

    // Handle window hash change
    window.addEventListener('hashchange', this.handleHashChange);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.eventLoaded && !this.props.passwordProtected) {
      document.body.classList.remove('modal-open');
    }

    // Scroll back to the top of the grid when the media list resets
    const { photoIndex } = this.props;
    if (photoIndex.length === 0 && prevProps.photoIndex.length !== 0) {
      scroller.scrollTo('eventMedia', {
        duration: 1000,
        smooth: true,
      });
    }

    // Handle password access
    const { passwordToken, eventId, dispatch } = this.props;
    const { eventToken } = this.state;
    const localToken = retrieveFromLocalStorage(`eventToken${eventId}`);

    // If token exists, request the event data
    const token = passwordToken?.token || localToken;
    if (token && token !== eventToken) {
      saveToLocalStorage(`eventToken${eventId}`, passwordToken.token);
      dispatch(requestEvent(eventId));
      this.setState({ isEventMounted: true, eventToken: token });
      return;
    }

    // Handle token error
    if (passwordToken?.error && !prevProps.passwordToken?.error) {
      removeFromLocalStorage(`eventToken${eventId}`);
      this.setState({ isEventMounted: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.delayedResize);
    window.removeEventListener('hashchange', this.handleHashChange);
  }

  onResize() {
    this.setState({
      windowSize: {
        h: window.innerHeight,
        w: window.innerWidth,
      },
    });
  }

  async onTileClick(id) {
    logEvent('media', 'open capture modal', `${this.props.eventId}/${id}`);

    await this.setActiveMedia(id);

    this.overlayToggle();
  }

  async setActiveMedia(id) {
    const { eventId, photoIndex, dispatch, filters, hasMore } = this.props;

    if (this.state.buildingItems || this.state.loadingMedia) {
      return;
    }

    this.setState({ loadingMedia: true });

    dispatch(requestMedia(id, eventId));

    // Get next + previous media for overlay
    const currentIndex = photoIndex.indexOf(id);
    let prevId;
    let nextId;
    if (filters.order !== 'ASC') {
      prevId = photoIndex[currentIndex - 1] || photoIndex[photoIndex.length - 1];
      nextId = photoIndex[currentIndex + 1];
      if (!nextId) {
        if (hasMore) {
          nextId = await this.fetchMore().mediaId;
        } else {
          nextId = photoIndex[0];
        }
      }
    } else {
      nextId = photoIndex[currentIndex - 1] || photoIndex[photoIndex.length - 1];
      prevId = photoIndex[currentIndex + 1];
      if (!prevId) {
        if (hasMore) {
          prevId = await this.fetchMore().mediaId;
        } else {
          prevId = photoIndex[0];
        }
      }
    }

    this.setState({
      prevId,
      nextId,
      loadingMedia: false,
    });
  }

  overlayToggle() {
    this.setState((prevState) => ({
      overlayOpen: !prevState.overlayOpen,
    }));
  }

  slideshowToggle() {
    this.setState((prevState) => {
      if (!prevState.slideshowOpen) {
        if (fscreen.fullscreenEnabled) {
          fscreen.requestFullscreen(document.documentElement);
        }
        document.documentElement.classList.add('modal-open');
        window.location.hash = 'slideshow';
      } else {
        if (fscreen.fullscreenEnabled && fscreen.fullscreenElement !== null) {
          fscreen.exitFullscreen();
        }
        document.documentElement.classList.remove('modal-open');
        window.location.hash = '';
      }

      return {
        slideshowOpen: !prevState.slideshowOpen,
      };
    });
  }

  handleHashChange() {
    if (!window.location.hash && this.state.slideshowOpen) {
      this.slideshowToggle();
    }
  }

  onFilterClick(filters) {
    this.setState({ filtersModified: true });
    this.props.dispatch(setFilters(filters));
    this.resetGrid();
  }

  // Reset the media grid and scroll back to the top
  resetGrid() {
    this.setState({
      photos: [],
      pagesLoaded: 0,
      buildingItems: false,
    });

    // Clear media list in store
    this.props.dispatch(clearMedias());
    this.props.dispatch(updateEventClearMedias(this.props.eventId));
  }

  preload(element) {
    const media = element;
    return new Promise((resolve, reject) => {
      const filetype = media.src.split('.').pop();
      let type = 'image';
      if (['mp4', 'mov', 'm4v'].includes(filetype)) {
        type = 'video';
      }

      media.renderType = type;

      let img = new Image();
      img.src = media.src;

      if (type === 'video') {
        img.src = media.static;
      }

      const poll = setInterval(() => {
        if (img.naturalHeight) {
          clearInterval(poll);
          media.size = {
            h: img.naturalHeight,
            w: img.naturalWidth,
          };
          img = null;
          resolve(media);
        }
      }, 30);

      img.onerror = () => {
        clearInterval(poll);
        img = null;
        reject();
      };
    });
  }

  async fetchMore(callback) {
    // Return early if already building a page, loading initial page, or no more items exist
    if (this.state.buildingItems || !this.props.hasMore || this.props.loadingMedias) {
      return;
    }
    this.setState({ buildingItems: true });

    const filter = {
      order: 'DESC', // Include `order` to make sure this object isn't ignored in the saga if missing other props
      ...(this.props.filters || {}),
      captureKind: this.props.captureKinds || [],
    };

    // Request next page of media and wait for the results
    const action = requestMedias(this.props.eventId, null, null, filter);
    action[WAIT_FOR_ACTION] = (action) => action.type === 'LOADING_MEDIAS' && !action.value;
    this.props.dispatch(action).then(() => {
      const photos = Object.values(this.props.photos);
      const stateIds = this.state.photos.map((photo) => photo.id);

      // Build array of media to preload before building next page
      const manifest = [];
      photos.forEach((photo) => {
        if (photo.src && !stateIds.includes(photo.id)) {
          manifest.push(this.preload(photo));
        }
      });

      // Preload the manifest, filtering out any that had an error
      Promise.all(manifest.map((p) => p.catch(() => undefined))).then((result) => {
        this.setState({
          buildingItems: false,
          photos: this.state.photos.concat(result.filter((e) => e)),
        });
        // Run callback function if it exists
        if (callback) {
          callback();
        }
        // Return first photo from newly-loaded results
        return result[0];
      });
    });
  }

  handleEventPassword(password) {
    this.setState({ isEventMounted: false });
    this.props.dispatch(requestEventAccessWithPassword(this.props.eventId, password));
  }

  renderGridItems() {
    return this.state.photos.map((image, index) => (
      <GridTile
        key={image.id}
        image={image}
        onTileClick={this.onTileClick}
        windowSize={this.state.windowSize}
        overlayOpen={this.state.overlayOpen}
      />
    ));
  }

  render() {
    const {
      eventLoaded,
      hasMore,
      fullGalleryEnabled,
      passwordProtected,
      passwordToken,
      beta,
      loadingEvent,
      activeMedia,
    } = this.props;

    const { hint, error } = passwordToken;
    if (!passwordProtected) {
      let classEventPage = 'eventpage';
      if (beta) {
        classEventPage = 'eventpage-beta';
      }

      let noPhotosString = 'Nothing to see here yet. Check back soon!';
      if (this.state.filtersModified) {
        noPhotosString = 'Nothing could be found matching those filters.';
      }

      if (this.state.windowSize) {
        return (
          eventLoaded &&
          fullGalleryEnabled && (
            <>
              <Head>
                <meta name="theme-color" content={this.props.accentColor} />
              </Head>

              <EventToolbar
                onFilterClick={this.onFilterClick}
                apiName={this.props.apiName}
                openSlideshow={this.slideshowToggle}
              />

              <Element name="eventMedia" className={classEventPage}>
                {this.state.photos && (
                  <InfiniteScroll
                    isMasonry={true}
                    hasMore={hasMore}
                    loadMore={this.fetchMore}
                    dataLength={this.state.photos.length}
                  >
                    {this.renderGridItems()}
                  </InfiniteScroll>
                )}

                {!this.state.photos.length && !hasMore && (
                  <div className="no-photos">
                    <img className="no-photos__img" alt="" src="/static/frame.svg" />
                    <p>{noPhotosString}</p>
                  </div>
                )}

                <MediaOverlay
                  onClose={this.overlayToggle}
                  overlayOpen={this.state.overlayOpen}
                  buildingItems={this.state.buildingItems}
                  prevId={this.state.prevId}
                  nextId={this.state.nextId}
                  setActiveMedia={this.setActiveMedia}
                />
              </Element>

              {this.state.slideshowOpen && <EventSlideshow onClose={this.slideshowToggle} />}
            </>
          )
        );
      }
      return <div></div>;
    }
    if (this.state.hasMounted && !loadingEvent.loading) {
      return (
        <div>
          <EventPasswordModal
            name="gallery"
            label="Enter Gallery"
            hint={hint}
            error={error}
            submitEventPassword={(password) => this.handleEventPassword(password)}
          />
        </div>
      );
    }

    return <div></div>;
  }
}

Event.propTypes = {
  eventId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  background: PropTypes.string,
  accentColor: PropTypes.string,
  photos: PropTypes.objectOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  eventLoaded: PropTypes.bool.isRequired,
  pageType: PropTypes.string.isRequired,
};

export default withRouter(Event);
