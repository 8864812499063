import React from 'react';
import PropTypes from 'prop-types';

function leftArrow(color) {
  return (
    <svg fill={color} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  );
}

const rightArrow = (color) => (
  <svg fill={color} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

class Arrows extends React.Component {
  render() {
    return this.props.direction === 'prev' ? leftArrow(this.props.fontColor) : rightArrow(this.props.fontColor);
  }
}

Arrows.propTypes = {
  fontColor: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
};

export default Arrows;
