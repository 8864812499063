import React from 'react';
import Datetime from 'react-datetime';
import { isMobile } from '../../utils/utils';

class EventFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: this.props.filters.dateFrom || null,
      dateTo: this.props.filters.dateTo || null,
      order: this.props.filters.order || 'DESC',
      captureMode: this.props.filters.captureMode || null,
      filterByTime: !!(this.props.filters.dateFrom || this.props.filters.dateTo),
      filterByCaptureMode: !!this.props.filters.captureMode,
    };
    this.onFilterClick = this.onFilterClick.bind(this);
    this.onOrderChange = this.onOrderChange.bind(this);
    this.onCaptureModeChange = this.onCaptureModeChange.bind(this);
    this.onFilterByCaptureMode = this.onFilterByCaptureMode.bind(this);
    this.onFilterByTime = this.onFilterByTime.bind(this);
    this.isValidFromDate = this.isValidFromDate.bind(this);
    this.isValidToDate = this.isValidToDate.bind(this);
  }

  componentDidMount() {
    this.setState({ isMobile: isMobile() });
  }

  onFilterClick() {
    this.props.onFilterClick(this.state);
  }

  onOrderChange(e) {
    this.setState({
      order: e.currentTarget.value,
    });
  }

  onCaptureModeChange(e) {
    this.setState({
      filterByCaptureMode: true,
      captureMode: e.currentTarget.value,
    });
  }

  onFilterByCaptureMode(e) {
    if (e.currentTarget.checked) {
      this.setState({
        filterByCaptureMode: true,
        captureMode: 'Photo',
      });
    } else {
      this.setState({
        filterByCaptureMode: false,
        captureMode: null,
      });
    }
  }

  onFilterByTime(e) {
    if (e.currentTarget.checked) {
      this.setState({
        filterByTime: true,
      });
    } else {
      this.setState({
        filterByTime: false,
        dateFrom: null,
        dateTo: null,
      });
    }
  }

  isValidFromDate(current) {
    const today = Datetime.moment();
    const dateTo = this.state.dateTo;
    const startDate = Datetime.moment(this.props.startDate).subtract(1, 'days');
    if (dateTo) {
      return current.isSameOrBefore(dateTo) && current.isSameOrAfter(startDate);
    }
    return current.isSameOrBefore(today) && current.isSameOrAfter(startDate);
  }

  isValidToDate(current) {
    const today = Datetime.moment();
    const dateFrom = this.state.dateFrom;
    if (dateFrom) {
      return current.isSameOrAfter(dateFrom) && current.isSameOrBefore(today);
    }
    return current.isSameOrBefore(today) && current.isSameOrAfter(this.props.startDate);
  }

  render() {
    let disabled = '';
    if (this.props.loading) {
      disabled = 'disabled';
    }

    const btnClasses = ['btn'];
    if (this.props.accentColor === '#FFFFFF') {
      btnClasses.push('gray-border');
    }

    return (
      <div className="event-filters">
        <section className="event-filters__section">
          <h2 className="event-filters__heading">Sort</h2>

          <div className="event-filters__radios">
            <input
              type="radio"
              name="order"
              value="DESC"
              checked={this.state.order === 'DESC'}
              onChange={this.onOrderChange}
              id="descInput"
            />
            <label htmlFor="descInput">Newest &rarr; Oldest</label>

            <input
              type="radio"
              name="order"
              value="ASC"
              checked={this.state.order === 'ASC'}
              onChange={this.onOrderChange}
              id="ascInput"
            />
            <label htmlFor="ascInput">Oldest &rarr; Newest</label>
          </div>
        </section>

        <section className="event-filters__section">
          <h2 className="event-filters__heading">Filters</h2>

          <div className="event-filters__checkbox">
            <input
              type="checkbox"
              checked={this.state.filterByCaptureMode}
              name="filterByCaptureMode"
              onChange={this.onFilterByCaptureMode}
              id="styleInput"
            />
            <label htmlFor="styleInput">Filter by style</label>
          </div>

          <div
            className="event-filters__radios event-filters__radios--small"
            style={{ opacity: this.state.filterByCaptureMode ? '1' : '0.5' }}
          >
            <input
              type="radio"
              name="captureMode"
              value="Photo"
              checked={this.state.captureMode === 'Photo'}
              onChange={this.onCaptureModeChange}
              id="photoInput"
            />
            <label htmlFor="photoInput">Photo</label>

            <input
              type="radio"
              name="captureMode"
              value="Video"
              checked={this.state.captureMode === 'Video'}
              onChange={this.onCaptureModeChange}
              id="videoInput"
            />
            <label htmlFor="videoInput">Video</label>

            {this.props.apiName === 'salsa' && (
              <div>
                <input
                  type="radio"
                  name="captureMode"
                  value="Boomerang"
                  checked={this.state.captureMode === 'Boomerang'}
                  onChange={this.onCaptureModeChange}
                  id="boomerangInput"
                />
                <label htmlFor="boomerangInput">Boomerang</label>

                <input
                  type="radio"
                  name="captureMode"
                  value="GIF"
                  checked={this.state.captureMode === 'GIF'}
                  onChange={this.onCaptureModeChange}
                  id="gifInput"
                />
                <label htmlFor="gifInput">GIF</label>
              </div>
            )}
          </div>

          <hr />

          <div className="event-filters__checkbox">
            <input
              type="checkbox"
              checked={this.state.filterByTime}
              name="filterByTime"
              onChange={this.onFilterByTime}
              id="timeInput"
            />
            <label htmlFor="timeInput">Filter by time</label>
          </div>

          <div style={{ opacity: this.state.filterByTime ? '1' : '0.5' }}>
            <div className="event-filters__input">
              <label htmlFor="dateFromInput">From Date</label>
              <Datetime
                id="dateFromInput"
                isValidDate={this.isValidFromDate}
                value={this.state.dateFrom}
                viewDate={Datetime.moment(this.props.startDate)}
                onFocus={() => this.setState({ filterByTime: true })}
                onChange={(current) => {
                  this.setState({
                    dateFrom: current,
                    filterByTime: true,
                  });
                }}
                inputProps={{ readOnly: this.state.isMobile }}
              />
            </div>

            <div className="event-filters__input">
              <label htmlFor="dateToInput">To Date</label>
              <Datetime
                id="dateToInput"
                isValidDate={this.isValidToDate}
                value={this.state.dateTo}
                viewDate={Datetime.moment(this.props.startDate)}
                onFocus={() => this.setState({ filterByTime: true })}
                onChange={(current) => {
                  this.setState({
                    dateTo: current,
                    filterByTime: true,
                  });
                }}
                inputProps={{ readOnly: this.state.isMobile }}
              />
            </div>
          </div>
        </section>

        <div className="event-filters__apply">
          <button
            className={btnClasses.join(' ')}
            onClick={this.onFilterClick}
            disabled={disabled}
            style={{
              backgroundColor: this.props.accentColor,
              color: this.props.contrastColor,
            }}
          >
            Apply
          </button>
        </div>
      </div>
    );
  }
}

export default EventFilter;
